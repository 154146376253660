import React, { FC, ReactElement } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { ConnectedPage } from '../connectors';
import { SliceRenderer } from '../containers';
import { transformToMetaData } from '../models/Meta';

const SecurityAndCompliance: FC = (): ReactElement => {
    const data = useStaticQuery(graphql`
        query SecurityAndComplianceComponents {
            slices: allPrismicSecurityAndCompliance {
                edges {
                    node {
                        dataRaw
                    }
                }
            }
        }
    `);

    const apiData = data.slices.edges[0].node.dataRaw;

    const slices = apiData.body;
    const metaData = apiData.meta.length
        ? transformToMetaData(apiData.meta[0])
        : undefined;

    return (
        <ConnectedPage metaData={metaData} className="security-and-compliance-page">
            <SliceRenderer
                slices={slices}
                className="security-and-compliance-page__slice-renderer"
            />
        </ConnectedPage>
    );
};

export default SecurityAndCompliance;
